<template>
  <v-app class>
    <div>
      <v-alert v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-10 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex">
            <div class="col-md-11">
              <h1>Pending Deductions</h1>
            </div>
            <!-- <div class="col-md-1">
              <Button :btnType="'Submit'" :label="'+'" @onClick="addNew" class="mr-2" />
            </div> -->
          </div>
          <v-text-field v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details></v-text-field>
          <v-data-table :loading="isLoading"
            loading-text="Fetching records... Please wait"
            :headers="headers"
            :items="deductionList"
            :search="search">
            <template v-slot:item.id="{ item }">
              {{ getEmployeeName(item.employee) }}
            </template>
            <template v-slot:item.effectiveDate="{ item }">
              {{ item.effectiveDate | formatDate }}
            </template>
            <template v-slot:item.dedecutionAmount="{ item }">
              {{ item.dedecutionAmount | formatMoney }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <Button :btnType="'Submit'" :label="'Approve'" @onClick="openApprove(item)" class="mr-2" />
                <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openRejectModal(item)" class="mr-2" />
              </div>
            </template>
          </v-data-table>
        </v-container>
        <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField v-model="comments" :label="'Comment'" />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button :label="'Reject'"
                  :btnType="'Submit'"
                  @onClick="reject"
                  :isLoading="isProcessing"
                  :disabled="!valid"
                  class="mr-4" />
                <v-btn color="green darken-1" text @click="closeRejectModal">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isProcessing" :dialog="dialog" :btnTitle="'Yes'" @close="closeApprove" @btnAction="approve"/>
      </div>
    </div>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
// import Dialog from '@/components/ui/Dialog.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { employeeDeductionService } from '@/services'
// import moment from 'moment'
// import { UPDATE_JOBTITLE, FETCH_EMPLOYEES, DELETE_JOBTITLE } from '@/store/action-type'
export default {
  components: {
    Button,
    // Dialog,
    TextField,
    ConfirmationDialog
  },
  data () {
    return {
      search: '',
      item: null,
      dialog: false,
      isLoading: false,
      comments: '',
      rejectDialog: false,
      isProcessing: false,
      formData: {
        name: '',
        id: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      medicalRecords: [],
      deductionList: [],
      headers: [
        {
          text: 'Personnel',
          value: 'id'
        },
        {
          text: 'Deduction Type',
          value: 'salaryComponent.description'
        },
        {
          text: 'Amount',
          value: 'dedecutionAmount'
        },
        {
          text: 'Effective Date',
          value: 'effectiveDate'
        },
        {
          text: 'Year',
          value: 'deductionYear'
        },
        {
          text: 'Department',
          value: 'employee.department.name'
        },
        {
          text: 'Location',
          value: 'employee.location.name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    // employees () {
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    addNew () {
      this.$router.push('/personnel-deduction/add')
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    openApprove (item) {
      this.dialog = true
      this.item = item
    },
    closeApprove () {
      this.dialog = false
      this.item = null
    },
    openRejectModal (item) {
      this.rejectDialog = true
      this.item = item
    },
    closeRejectModal () {
      this.rejectDialog = false
      this.item = null
    },
    reject () {
      this.isProcessing = true
      const data = {
        id: this.item.id,
        status: 2,
        comments: this.comments
      }

      employeeDeductionService.rejectEmployeeDeduction(data).then(() => {
        this.showAlertMessage('Deduction successfully rejected', 'success')
        this.getDeductions()
        this.rejectDialog = false
        // that.$refs.deleteModal.toggleModal()
      }).catch(() => {
        this.showAlertMessage('Unable to reject deduction', 'error')
      }).finally(() => {
        this.isProcessing = false
      })
    },
    approve () {
      this.isProcessing = true
      const data = {
        id: this.item.id,
        status: 1,
        comments: ''
      }

      employeeDeductionService.approveEmployeeDeduction(data).then(() => {
        this.showAlertMessage('Deduction successfully approved', 'success')
        this.getDeductions()
        this.dialog = false
      }).catch(() => {
        this.showAlertMessage('Unable to approve deduction', 'error')
      }).finally(() => {
        this.isProcessing = false
      })
    },
    cancel (modal) {
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    formatStatus (status) {
      switch (status) {
        case '0':
          return 'Pending'
        case '1':
          return 'Approved'
        case '2':
          return 'Rejected'
        case '7':
          return 'Cancelled'
        default:
          return ''
      }
    },
    getDeductions () {
      this.isLoading = true
      employeeDeductionService.getPendingEmployeeDeductions().then(result => {
        this.deductionList = result.data.items
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    getEmployeeName (employee) {
      return `${employee.lastName}, ${employee.firstName} ${employee.middleName}`
    }
  },
  mounted () {
    this.getDeductions()
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .row-pointer > > > tbody tr :hover {
    cursor: pointer;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
</style>
